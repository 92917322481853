.App {
  text-align: center;
  justify-content: center;
  max-width: 600px;
  margin-left:auto; 
  margin-right:auto;
}

#HazeData {
  margin-right:auto; 
  padding: 10px;
}

.hazardous { background: #69001A; color: #ffffff; }
.veryunhealthy { background: #85003B; color: #ffffff; }
.unhealthy { background: #FC0006; color: #000000; }
.usg { background: #FC6807; color: #000000; }
.moderate { background: #ffff00; color: #000000; }
.good { background: #1EE404; color: #000000; }

.light { opacity: 0.5;}

#HazeGraph {
  margin-left:5px; 
  margin-right:10px; 
}

#references {
  text-align: center;
  color: #747474;
  font-size: calc(12px);
}

#bands {
  margin-left:auto; 
  margin-right:auto;
}

#citation {
  text-align: justify;
  margin-left:5%; 
  margin-right:5%; 
}

#datasource {
  text-align: right;
  margin-right:10%; 
}
